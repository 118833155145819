<template>
  <span class="breadcrumb-item">
    <span :class="to?'is-link':''" @click="jump"><slot></slot></span>
    <i>&gt;</i>
  </span>
</template>
<script>
  export default {
    name: 'BreadcrumbItem',
    props: {
      to: {}
    },
    data () {
      return {};
    },
    methods: {
      jump () {
        if (this.to) {
          this.$router.replace(this.to);
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .breadcrumb-item{
    display:inline-block;
    >span{vertical-align:middle;}
    &:last-child{
      i{display:none;}
    }
    i{display:inline-block;margin:0 6px;vertical-align:middle;}
    .is-link{
      color:#1890ff;cursor:pointer;
      &:hover{text-decoration:underline;}
    }
  }
</style>
