<template>
  <div class="main">
    <breadcrumb>
      <breadcrumb-item>{{$t('OrderManagement')}}</breadcrumb-item>
      <breadcrumb-item>{{$t('ProductNumber')}}：001</breadcrumb-item>
      <breadcrumb-item>{{$t('All passenger information')}}</breadcrumb-item>
    </breadcrumb>
    <div class="product-info">
      <table>
        <thead>
        <tr>
          <th>{{$t('ProductNumber')}}</th>
          <th>{{$t('ProductName')}}</th>
          <th>{{$t('Full number of people')}}</th>
          <th>{{$t('GroupOfPeople')}}</th>
          <th>{{$t('Order people number')}}</th>
          <th>{{$t('Group status')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>100001</td>
          <td>中國南京三天遊</td>
          <td>2人</td>
          <td>4人</td>
          <td>已成团</td>
          <td>充足</td>
        </tr>
        </tbody>
      </table>
      <button class="button" type="button">{{$t('All order materials')}}</button>
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th>{{$t('Order number')}}</th>
          <th>{{$t('Appellation')}}</th>
          <th>{{$t('English name')}}</th>
          <th>{{$t('Gender')}}</th>
          <th>{{$t('Certificate Type')}}</th>
          <th>{{$t('Certificate of Issue')}}</th>
          <th>{{$t('Certificate Number')}}</th>
          <th>{{$t('Certificate Validity Period')}}</th>
          <th>{{$t('DateOfBirth')}}</th>
          <th>{{$t('Mobile Number')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="i in 10" :key="i">
          <td>100011</td>
          <td>Mr.</td>
          <td>Chan/Ka Wai</td>
          <td>男</td>
          <td>護照</td>
          <td>中國</td>
          <td>H7286437823</td>
          <td>2020年7月1日</td>
          <td>23-08-1989</td>
          <td>(852)7861 2112</td>
        </tr>
        </tbody>
      </table>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :total="1000">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import Breadcrumb from '../../../components/admin/Breadcrumb';
  import BreadcrumbItem from '../../../components/BreadcrumbItem';

  export default {
    name: 'AdminOrderListTourists',
    data() {
      return {};
    },
    components: {Breadcrumb, BreadcrumbItem},
    created() {
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .product-info{
    display:flex;margin-top:20px;padding-top:20px;background-color:#f4f4f4;
    .button{align-self:flex-start;min-width:auto;margin-right:8px;padding:9px 10px;font-size:12px;}
    table{
      flex:1;margin-right:32px;
      th,
      td{
        padding-left:16px;padding-right:16px;text-align:left;
        &:first-child,
        &:last-child{padding-left:8px;padding-right:8px;}
      }
      th{white-space:nowrap;}
      td{padding-top:20px;padding-bottom:20px;}
    }
  }
</style>
