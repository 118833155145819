<template>
  <breadcrumb>
    <slot></slot>
  </breadcrumb>
</template>
<script>
  import Breadcrumb from '../Breadcrumb';

  export default {
    name: 'AdminBreadcrumb',
    components: {Breadcrumb}
  };
</script>
<style scoped lang="less">
  .breadcrumb{
    padding:0 12px;line-height:34px;background-color:#fbfbfb;
    /deep/ .breadcrumb-item{
      color:#ff6f61;font-size:16px;
      .is-link{color:#ff6f61;}
      > span{display:inline-block;line-height:34px;vertical-align:middle;}
    }
  }
</style>
