<template>
  <div class="breadcrumb">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'Breadcrumb'
  };
</script>
<style scoped lang="less">
  .breadcrumb{padding:20px 0;}
</style>
